<template>
    <div class="surface-card p-6 border-1 surface-border">
        <update-view-header
            :headline="entity.id ? (entity.email + ' bearbeiten') : 'Benutzer hinzufügen'"
            button-path="/users"
            :button-text="$t('Back to overview')"
        />

        <form v-on:submit.prevent="submitEntity" v-if="editedEntity.id || $route.params.id === 'create'">
            <div class="field grid" v-for="field in fields" :key="index">
                <label class="col-fixed text-700 mt-1 w-4">
                    {{ field.label }}
                </label>
                <div class="col">
                    <checkbox
                        v-if="field.key === 'active'"
                        v-model="editedEntity[field.key]"
                        :binary="true"
                    />

                    <EntitySelect
                        type="company"
                        v-else-if="field.key === 'company'"
                        v-model="editedEntity[field.key]"
                        :multiple="false"
                    />

                    <Dropdown
                        v-else-if="field.key === 'locale'"
                        v-model="editedEntity[field.key]"
                        :options="[{id: 'de', label: 'Deutsch'}, {id: 'en', label: 'Englisch'}]"
                        option-label="label"
                        option-value="id"
                        class="w-full"
                    />

                    <InputText
                        v-else
                        class="w-full"
                        type="text"
                        required
                        v-model="editedEntity[field.key]"
                    />

                    <small class="p-invalid block mt-2" v-for="message in editedEntityErrors[field.key]" :key="message">
                        {{ message }}
                    </small>
                </div>
            </div>

            <permissions
                v-model="editedEntity.permissions"
            />

            <div class="surface-border border-top-1 opacity-50 mb-4 mt-4 py-0 col-12"></div>

            <div class="text-center">
                <Button type="submit" :label="$t('Save')"></Button>
            </div>
        </form>
    </div>
</template>

<script>
    import InputText from "primevue/inputtext/InputText";
    import Dropdown from "primevue/dropdown/Dropdown";
    import Button from "primevue/button";
    import Permissions from "@/components/form/Permissions";
    import EntitySelect from "@/components/form/EntitySelect";
    import UpdateView from "@/mixin/UpdateView";
    import Checkbox from "primevue/checkbox/Checkbox";
    import UpdateViewHeader from "@/components/view/UpdateViewHeader";

    export default {
        mixins: [UpdateView],
        components: {
            UpdateViewHeader,
            EntitySelect,
            Permissions,
            InputText,
            Button,
            Checkbox,
            Dropdown
        },
        data() {
            return {
                fields: [
                    {
                        key: 'active',
                        label: 'Aktiv',
                    },
                    {
                        key: 'email',
                        label: 'E-Mail',
                    },
                    {
                        key: 'company',
                        label: 'Unternehmen',
                    },
                    {
                        key: 'locale',
                        label: 'Sprache',
                    },
                ]
            }
        },
        methods: {
            getApiEndpoint() {
                return 'user-reals';
            },
            getCreationAction(entity) {
                return this.$router.push('/users/' + entity.id);
            },
        }
    }
</script>
